<template>
  <div class="d-flex justify-content-center">
    <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{$t('creator.addAConnection')}}</h1>
        <p>{{$t('creator.text_adding_connection')}}</p>
      </b-col>

      <b-col class="col-12 text-center">
        <b-button @click="openSiderbar()" variant="btn-add-connection" class="my-2 btn-add-connection">{{$t('creator.text_button_connection')}} </b-button>
      </b-col>

      <b-col class="col-12 text-center">
        <b-form-radio-group>
          <b-col 
            class="col-12 mt-1 mx-auto active-class-button" 
            v-for="(network, index) in connections" 
            :key="index"
            ref="card_radio_button"
          >
            <div class="card-radion-button-steps-networks d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <b-avatar :src="getImageNetworkColor(network.network)" class="bg-white mr-1"></b-avatar>
                <div class="text-left">
                  <strong>{{nameNetworks(network.network)}}</strong>
                  <span class="text-muted d-block mt-1"> @{{(network.network === 'linkedin' ? network.name : network.username)}}</span>
                </div>
              </div>
              <b-form-checkbox :disabled="true" class="float-right" :checked="true"></b-form-checkbox>
            </div>
          </b-col>
        </b-form-radio-group>
      </b-col>

      <b-col class="col-12 d-flex justify-content-center">
        <b-button variant="blue-button-next-step" class="blue-button-next-step my-2" @click="save()">{{$t('creator.butonNext')}}</b-button>
      </b-col>
      <b-col class="col-12 d-flex justify-content-center">
        <p>{{$t('creator.moreConnections')}}</p>
      </b-col>
    </b-row>

    <sider-network 
      class="open-sidebar-search"
      @close_siderbar="closeSiderNetwork"
      @updated_network="getNetworks"
      :show_sider_network="show_sider_network"
      :key="change_sider"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BAvatar,
  BFormRadioGroup,
  BFormCheckbox
} from 'bootstrap-vue';
import siderNetwork from '../../pages/proposals/stepper/siderNetwork.vue';
import service from "@/services/others";
import { nameNetworks } from '@/libs/utils/formats'
import { getImageNetworkColor } from '@/libs/utils/icons'

export default {
  name: 'PublicProfileConnections',
  components: {
    BRow,
    BCol,
    BButton,
    BAvatar,
    BFormRadioGroup,
    BFormCheckbox,
    siderNetwork
  },
  data() {
    return {
      nameNetworks,
      getImageNetworkColor,
      connections: [],
      show_sider_network: false,
      change_sider: false
    }
  },
  created() {
    this.getNetworks();
  },
  methods: {
    getNetworks() {
      service.getSocialNetworks().then((response) => {
        this.connections = response.results;
      })
    },
    openSiderbar() {
      this.show_sider_network = true;
      this.change_sider = !this.change_sider
    },
    closeSiderNetwork() {
      this.show_sider_network = false;
      this.change_sider = !this.change_sider
    },
    save() {
      this.$router.push({ name: 'setting-public-profile', params: {section: 'profile'}})
    },
  },
}
</script>
<style>
.card-radion-button-steps-networks {
  border-radius: 1em;
  background-color: white;
  overflow: hidden !important;
  padding: 1em;
  border: 1px solid rgba(210, 210, 210, 0.7); 
  position: relative;
}
.card-radion-button-steps-networks:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  cursor: pointer;
  transition: 500ms;
}
.active-class-button > .card-radion-button-steps-networks {
  background-color: #7267f01b !important;
  border: 1px solid #7367f0;
}
</style>
<style scoped>
.btn-add-connection {
  color: #55a6c4 !important;
  background-color: #EEF6F9 !important;
  border-color: rgba(0,0,0,0) !important;
}
.btn-add-connection:hover {
  background-color: #55A6C4 !important;
  color: #F6FBFC !important;
}
</style>