var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-center"},[_c('b-row',{staticClass:"mt-3 col-12 col-lg-8 col-xl-5"},[_c('b-col',{staticClass:"col-12 text-center"},[_c('h1',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('creator.titleFilling'))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t('creator.text_add_Avatar')))])]),_c('b-col',{staticClass:"col-12 text-center mt-3"},[_c('h3',{staticClass:"text-left"},[_vm._v("Avatar")]),_c('b-card',{staticClass:"card-container-avatar",attrs:{"body-class":"border-card-avatar","header-class":"header-color"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-avatar',{staticClass:"avatar-profile",attrs:{"variant":"light","src":_vm.avatar,"size":"10em"}}),_c('div',[_c('b-avatar',{staticClass:"avatar-edit",attrs:{"variant":"light","href":"#"},on:{"click":function($event){return _vm.modalImage()}}},[_c('b-icon',{attrs:{"icon":"pencil-fill"}})],1)],1)]},proxy:true}])},[_c('div',{staticClass:"mt-4"})])],1),_c('validation-observer',{ref:"save",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('b-col',{staticClass:"col-12 mt-3"},[_c('h3',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t('creator.profileOverview')))]),_c('b-row',[_c('b-col',{staticClass:"col-12"},[_c('b-row',[_c('b-col',{staticClass:"col-6"},[_c('b-form-group',[_c('label',{attrs:{"for":"account-username"}},[_vm._v(_vm._s(_vm.$t('name')))]),_c('validation-provider',{attrs:{"name":_vm.$t('name'),"vid":_vm.$t('name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('name'),"state":_vm.first_name.length > 0 && !_vm.existsEmailOrPhoneOrUlr(_vm.first_name)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }!invalid ? _vm.save(): ''}},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))]),(_vm.existsEmailOrPhoneOrUlr(_vm.first_name) && _vm.loaded)?_c('small',{staticClass:"text-danger w-100"},[_vm._v(_vm._s(_vm.$t('negativeContent.findInfoContact')))]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{staticClass:"col-6"},[_c('b-form-group',[_c('label',{attrs:{"for":"account-lastname"}},[_vm._v(_vm._s(_vm.$t('lastname')))]),_c('validation-provider',{attrs:{"name":_vm.$t('lastname'),"vid":_vm.$t('lastname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('lastname'),"state":_vm.last_name.length > 0 && !_vm.existsEmailOrPhoneOrUlr(_vm.last_name)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }!invalid ? _vm.save(): ''}},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.existsEmailOrPhoneOrUlr(_vm.last_name) && _vm.loaded)?_c('small',{staticClass:"text-danger w-100"},[_vm._v(_vm._s(_vm.$t('negativeContent.findInfoContact')))]):_vm._e()]}}],null,true)})],1)],1)],1)],1),_c('b-col',{staticClass:"col-12"},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('dateBirth')))]),_c('validation-provider',{attrs:{"name":_vm.$t('birthday'),"vid":_vm.$t('birthday'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.loaded)?_c('input-calendar',{ref:"input_calendar",attrs:{"real_date":_vm.birthday,"state":errors.length > 0 ? false : null}}):_vm._e(),(_vm.$refs['input_calendar'] && Object.keys(_vm.$refs['input_calendar']).length > 0 && _vm.$refs['input_calendar'].date.length === 0)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('birthdayRequired')))]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t('creator.descriptionBio')))]),_c('validation-provider',{attrs:{"name":_vm.$t('creator.question_text'),"vid":_vm.$t('creator.question_text'),"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{class:{'is-invalid': !!errors.length},attrs:{"state":_vm.question_text.length > 0 && !_vm.existsEmailOrPhoneOrUlr(_vm.question_text)},model:{value:(_vm.question_text),callback:function ($$v) {_vm.question_text=$$v},expression:"question_text"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.existsEmailOrPhoneOrUlr(_vm.question_text) && _vm.loaded)?_c('small',{staticClass:"text-danger w-100"},[_vm._v(_vm._s(_vm.$t('negativeContent.findInfoContact')))]):_vm._e()]}}],null,true)})],1)],1)],1)],1),_c('b-col',{staticClass:"col-12 d-flex justify-content-center"},[_c('b-button',{staticClass:"blue-button-next-step my-2",attrs:{"disabled":invalid || _vm.existsEmailOrPhoneOrUlr(_vm.first_name + _vm.last_name + _vm.question_text),"variant":"blue-button-next-step"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.getTextButton()))])],1),_c('b-col',{staticClass:"col-12 d-flex justify-content-center"},[_c('p',[_vm._v(_vm._s(_vm.$t('creator.text_age_range')))])])],1)]}}])})],1),_c('b-modal',{ref:"change-avatar",attrs:{"id":"modal-center","centered":"","title":_vm.$t('dashboard.changeAvatar'),"ok-title":_vm.$t('dashboard.save'),"ok-only":""},on:{"ok":_vm.changeAvatar}},[_c('b-form-file',{attrs:{"id":"file-large","size":"lg","placeholder":_vm.$t('dashboard.chooseFile'),"drop-placeholder":"$t('dashboard.dropFile')","accept":".jpg, .png","browse-text":_vm.$t('uploadFile')},model:{value:(_vm.file_avatar),callback:function ($$v) {_vm.file_avatar=$$v},expression:"file_avatar"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }